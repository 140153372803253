<template>
  <q-dialog transition-show="slide-up" transition-hide="slide-down" persistent>
    <q-card class="form">
      <div class="q-pa-xs">
        <q-card-section class="row items-center q-pb-lg">
          <div
            style="font-size: 14pt"
            class="text-uppercase text-grey-8 q-mt-sm"
            v-html="$t('missions.enroll_modal')"
          />
          <q-space />
          <q-btn
            flat
            round
            dense
            icon="close"
            color="red"
            v-close-popup
            @click="clearData()"
          />
        </q-card-section>

        <q-separator class="q-mr-md q-ml-md" />

        <q-form @submit="step == 1 ? submit(slug) : submitAnswers(slug)" greedy>
          <q-card-section class="q-pt-none q-mt-md">
            <div v-if="step == 1">
              <partner-account-component
                :nullData="nullData"
                :completedData="completedData"
                :getPartnerAccount="getPartnerAccount"
              />
              <div>
                <div class="q-pa-md default-rounded text-justify">
                  <span v-html="$t('missions.enroll_conditions')" />
                </div>
                <div class="row">
                  <div class="col-md-6" style="margin: auto">
                    <q-input dense autofocus filled v-model="confirmValue" />
                  </div>
                </div>
              </div>
              <div class="q-gutter-sm q-mt-md">
                <span
                  ><q-checkbox v-model="terms" />
                  <span
                    @click="showTermFn()"
                    v-html="$t('missions.terms')"
                    class="term-link"
                  />
                </span>
              </div>
            </div>

            <div v-if="step == 2">
              <div
                class="bg-yellow-4 q-pa-md default-rounded q-mb-lg text-justify"
              >
                <span v-html="$t('missions.enroll_questions')" />
              </div>
              <div class="" v-for="(q, i) in questions" :key="i">
                <div v-html="`${i + 1} - ${q.question}`" class="q-mt-sm" />
                <q-input
                  dense
                  filled
                  class="q-mt-sm"
                  v-model="q.answer"
                  :rules="[
                    (value) => !!value || $t('missions.required_answer'),
                  ]"
                />
              </div>
            </div>

            <q-separator
              :class="{
                'q-ma-md': $q.screen.width > 800,
                'q-ma-xs': $q.screen.width <= 800,
              }"
            />
            <q-card-actions align="right">
              <q-btn
                flat
                v-if="step == 1"
                unelevated
                color="dark"
                icon="person"
                :to="{ name: 'account.edit' }"
                :label="$t('account.account')"
                class="default-rounded text-weight-regular"
              />
              <q-btn
                unelevated
                color="dark"
                type="submit"
                :loading="in_request"
                :disable="!ableButton()"
                :label="
                  $t(
                    $q.screen.width > 800
                      ? 'missions.to_progress'
                      : 'Prosseguir'
                  )
                "
                class="default-rounded text-weight-regular"
              />
            </q-card-actions>
          </q-card-section>
        </q-form>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import PartnerAccountComponent from "./PartnerAccountComponent.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { accountData } from "../../helpers/script_helpers";
import { notifySuccess } from "@/shared/helpers/notify";
import { onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const usePartner = createNamespacedHelpers("account"),
  { useActions, useGetters } = createNamespacedHelpers("missions");

export default {
  name: "SubscribeComponent",

  components: { PartnerAccountComponent },

  props: {
    slug: {
      type: String,
      required: true,
    },
    term: {
      type: String,
      required: true,
    },
  },

  emits: ["showTerm"],

  setup(props, { emit }) {
    const step = ref(1),
      router = useRouter(),
      { t } = useI18n(),
      questions = ref([]),
      nullData = ref([]),
      completedData = ref([]),
      confirmValue = ref(""),
      terms = ref(false),
      showTerm = ref(false),
      in_request = ref(false);

    const { getSubsQuestions } = useGetters(["getSubsQuestions"]),
      { getPartnerAccount } = usePartner.useGetters(["getPartnerAccount"]),
      { fetchSubscribe, fetchSendAwnswers, fetchMissionsServices } = useActions(
        ["fetchSubscribe", "fetchSendAwnswers", "fetchMissionsServices"]
      );

    const data = computed(() => accountData(getPartnerAccount.value.pf_pj));

    const getPartnerData = () => {
      data.value.forEach((i) => {
        if (!getPartnerAccount.value[i]) nullData.value.push(i);
        if (getPartnerAccount.value[i]) completedData.value.push(i);
      });
    };

    const submit = async (slug) => {
        in_request.value = true;
        await fetchSubscribe({ slug })
          .then(async () => {
            if (!Object.keys(getSubsQuestions.value).includes("mission_id")) {
              questions.value = getSubsQuestions.value.map((i) => ({
                answer: "",
                behavior_id: i.behavior.id,
                question: i.behavior.question,
              }));
              step.value = 2;
            } else
              await fetchMissionsServices().then(() => {
                notifySuccess(t("missions.register_success"));
                localStorage.setItem("isSubscribed", true);
                router.push({ name: "missions" });
              });
          })
          .finally(() => (in_request.value = false));
      },
      submitAnswers = async (slug) => {
        in_request.value = true;
        const payload = {
          mission_slug: slug,
          answers: questions.value,
        };
        await fetchSendAwnswers(payload).then(() => submit(slug));
      },
      clearData = () => {
        step.value = 1;
        questions.value = [];
        confirmValue.value = "";
      };

    const ableButton = () => {
      if (terms.value == false) return false;
      else
        return (
          nullData.value.length == 0 &&
          confirmValue.value.toLowerCase().trim() == t("missions.confirm")
        );
    };

    onMounted(() => getPartnerData());

    return {
      step,
      terms,
      showTerm,
      nullData,
      questions,
      in_request,
      confirmValue,
      completedData,
      getSubsQuestions,
      getPartnerAccount,
      submit,
      clearData,
      ableButton,
      submitAnswers,
      showTermFn: () => emit("showTerm"),
    };
  },
};
</script>

<style lang="scss" scoped>
.term-link {
  color: rgb(38, 46, 93);
  text-decoration: underline;
  cursor: pointer;
}
.border-left {
  border-left: 1px solid rgb(199, 198, 198);
}
.form {
  width: 900px;
  max-width: 80vw;
}
.mobile {
  .form {
    min-width: 98vw;
  }
}
</style>
