<template>
  <div class="bg-yellow-4 q-pa-md default-rounded text-justify">
    <span v-html="$t('missions.enroll_msg')" />
  </div>
  <div class="q-mt-md q-pa-md bg-grey-3 row border">
    <div class="col-12 col-md-6">
      <div
        class="text-uppercase q-mb-sm text-weight-bold "
        v-html="$t('missions.my_data')"
      />
      <div :key="i" v-for="(d, i) in completedData">
        <q-icon name="arrow_right" />
        <span
          v-html="$t(`missions.data.${d}`, { value: getPartnerAccount[d] })"
        />
      </div>
    </div>
    <div
      v-if="nullData.length"
      class="col-12 col-md-6"
      :class="$q.platform.is.desktop ? ' q-pl-md border-left' : ''"
    >
      <div
        class="text-uppercase q-mb-sm text-weight-bold"
        v-html="$t('missions.data_pending')"
      />
      <div :key="i" v-for="(d, i) in nullData">
        <q-icon name="close" color="red" /> {{ $t(`missions.${d}`) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnerAccountComponent",

  props: {
    completedData: {
      type: Array,
      required: true,
    },
    nullData: {
      type: Array,
      required: true,
    },
    getPartnerAccount: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.border-left {
  border-left: 1px solid rgb(199, 198, 198);
}
.border {
  border-radius: 5px;
}
</style>
