<template>
  <div class="q-pa-sm bg-white default-rounded ">
    <div
      class="row"
      :class="{
        'justify-center': $q.platform.is.mobile,
        'no-wrap justify-between': $q.platform.is.desktop,
      }"
    >
      <q-btn
        flat
        icon="reply"
        color="black-11"
        :to="{ name: 'missions' }"
        :label="$t('missions.back')"
      />
    </div>
  </div>
  <div class="q-mt-lg">
    <show-mission-header-component
      :mission="getShowMission"
      v-if="Object.keys(getShowMission).length"
    />
  </div>
  <div class="row q-mt-lg wrap-reverse">
    <show-mission-body-component
      @enroll="showModalFn()"
      :mission="getShowMission"
      v-if="Object.keys(getShowMission).length"
    />
  </div>
  <subscribe-component
    v-model="showModal"
    v-if="showModal"
    @showTerm="showTerm = true"
    :slug="getShowMission.slug"/>
  <show-term-component
    v-if="showTerm"
    v-model="showTerm"
    :term="getMissionTerms.terms_participation"
/></template>

<script>
import SubscribeComponent from "./components/internal-components/SubscribeComponent.vue";
import ShowMissionHeaderComponent from "./components/ShowMissionHeaderComponent.vue";
import ShowMissionBodyComponent from "./components/ShowMissionBodyComponent.vue";
import ShowTermComponent from "./components/internal-components/ShowTermComponent.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import { LocalStorage } from "quasar";
import Cookies from "js-cookie";

const { useGetters, useActions } = createNamespacedHelpers("missions");

export default {
  name: "ShowMissionPage",

  components: {
    ShowMissionHeaderComponent,
    ShowMissionBodyComponent,
    SubscribeComponent,
    ShowTermComponent,
  },

  setup() {
    const route = useRoute(),
      showModal = ref(false),
      showTerm = ref(false),
      { getShowMission, getMissionTerms } = useGetters([
        "getShowMission",
        "getMissionTerms",
      ]),
      { fetchShowMission, fetchTermByMission } = useActions([
        "fetchShowMission",
        "fetchTermByMission",
      ]);

    onMounted(async () => {
      if (!Object.keys(getShowMission.value).length)
        await fetchShowMission({ slug: route.params.slug });
      if (LocalStorage.getItem("wanna_enroll")) {
        if (getShowMission.value.participants.length == 0)
          showModal.value = true;
        LocalStorage.remove("wanna_enroll");
      }
      Cookies.remove("route-param-search");
      fetchTermByMission({ slug: route.params.slug });
    });

    return {
      showModal,
      showTerm,
      getMissionTerms,
      getShowMission,
      showModalFn: () => (showModal.value = !showModal.value),
    };
  },
};
</script>

<style scoped lang="scss">
.mobile {
  .content-missions {
    margin: 0 -15px;
  }
}
</style>
