<template>
  <q-dialog transition-show="slide-up" transition-hide="slide-down" persistent>
    <q-card class="form">
      <div class="q-pa-xs">
        <q-card-section class="row items-center q-pb-lg">
          <div
            style="font-size: 14pt"
            class="text-uppercase text-grey-8 q-mt-sm"
            v-html="$t('missions.terms_label')"
          />
          <q-space />
          <q-btn flat round dense icon="close" color="red" v-close-popup />
        </q-card-section>

        <q-separator class="q-mr-md q-ml-md" />

        <div class="q-ma-lg">
          <span v-html="term" />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "ShowTermComponent",

  props: {
    term: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 900px;
  max-width: 80vw;
}
.mobile {
  .form {
    min-width: 98vw;
  }
}
</style>
